//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gamePlayCopy from '../data/copy.js'
import GlobalMixins from "../../../../../shared/GlobalMixins";
import GamePlayMixin from "../../../../../shared/GamePlayMixin";
import { VueTyper } from 'vue-typer';

export default {
  name: "AnswerModal",
  mixins: [GlobalMixins, GamePlayMixin],
  components: {
    VueTyper
  },
  data () {
    return {
      copy: gamePlayCopy,
      answered: false,
      correct: false,
      correctCharacters: [],
      incorrectCharacters: [],
      reviewSequence: [],
    }
  },
  methods: {
    addNumberToSequence(number) {
      if (this.answerSequence.length < 10) {
        this.addToAnswerSequence(number - 1);
      }
    },
    removeLastNumberInSequence() {
      if (this.answered) return false;
      this.removeLastNumberAnswerInSequence();
    },
    async isAnswerCorrect() {
      console.log('isAnswerCorrect')
      if (this.answered) return false;
      await this.stopAndHideTimer();
      this.reviewSequence = JSON.parse(JSON.stringify([]));

      this.correct = JSON.stringify(this.correctAnswers) === JSON.stringify(this.answerSequence);

      this.characters(this.correct ? this.copy.trials.correct : this.copy.trials.incorrect);
      this.answered = true;

      console.log('reviewSequence', this.reviewSequence)
      for (const [index, answer] of [...this.answerSequence].entries()) {
        console.time('∞∞ highlight');

        if (this.correctAnswers[index] === answer) {
          // modifying the DOM directly here because of some race condition or rendering bug with Vue
          this.$refs.sequenceNumbers[index].classList.add('correct');
          this.setEffect(this.assets.audio.feedbackCorrect);

          await this.sleep(1000);
        } else {
          this.$refs.sequenceNumbers[index].classList.add('incorrect');
          this.setEffect(this.assets.audio.feedbackIncorrect);
          await this.sleep(1000);
        }

        console.timeEnd('∞∞ highlight');
      }



      this.stopMusic();

      if (this.correct) {
        this.setEffect(this.assets.audio.quizCorrect);
        this.correctThisRound = this.correctThisRound + 1;
        this.increaseTrialsCorrect();
        this.setTrialStatus('correct');
        this.trialWasCorrect = true;
      } else {
        this.setTrialStatus('incorrect');
        this.trialWasCorrect = false;
        this.setEffect(this.assets.audio.incorrect);
      }
      await this.sleep(1800);
      this.$emit('trialCompleted', this.correct);
    }
  },
  computed: {
    backgroundImage() {
      if (this.modalMessage.backgroundImage !== undefined) {
        return this.modalMessage.backgroundImage;
      }

      if (this.assets.images.modal !== undefined) {
        return this.assets.images.modal;
      }

      return '';
    },
    showSequenceOrFeedback() {
      if (!this.answered) {
        return this.answerSequence.join(', ');
      } else {
        return false;
      }
    },
    computedClasses() {
      return this.answerSequence.map((answer, index) => {
        if (this.reviewSequence.length < 1) return 'unchecked';
        return this.reviewSequence[index] ? 'correct' : 'incorrect';
      });
    }
  },
  mounted() {
    this.delay = 50;
    this.characters(this.copy.gamePlay.enterNumbers);
    this.setTimerStopAfter(1000);
    this.startTimer(200);
    this.clearAnswerSequence();
  }
}
